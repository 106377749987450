<template>
  <div>
    <el-dialog title="数据说明" :visible.sync="dialogVisible" width="1027" >
      <el-row>
        <el-col :span="11">


          <Select v-model="search" placeholder="搜索指标" clearable filterable @on-query-change="onQueryChange">
            <Option v-for="item in searchList" :value="item" :key="item">{{ item }}</Option>
          </Select>
        </el-col>
        <el-col :span="12">

          <!-- <el-button type="primary" class="bit" style="width: 154px;">搜索</el-button> -->

        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="margin-top: 16px;">
          <Table height="400" :columns="columns1" :data="tableData1" :span-method="handleSpan"></Table>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tableData: {
      type: Array,
      default: () => []
    }


  },
  data() {
    return {
      search: "",
      restaurants: [],
      state: '',
      timeout: null,
      searchList: [],
      assembleDataList: [],
      columns1: [
        {
          title: '分类',
          key: 'index'
        },
        {
          title: '指标名称',
          key: 'targetName'
        },
        {
          title: '地区范围',
          key: 'area'
        },
        {
          title: '发布频率',
          key: 'timePeriod'
        },
        {
          title: '数据起始时间',
          key: 'startDate'
        },
        {
          title: '数据结束时间',
          key: 'endDate'
        },
        // {
        //   title: '备注',
        //   key: ''
        // },
      ],

      tableData1: this.tableData
      // dialogVisible:true
    };
  },
  watch: {
    dialogVisible(val) {
            this.tableData.sort(function (a, b) {
          return b.index < a.index ? 1 : -1
        })
  this.assembleDataList =  this.assembleData(this.tableData)
      this.tableData1 =  this.assembleDataList

      this.searchList = this.tableData.map(
        item => {
          return item.targetName
        })
      this.searchList = [...new Set(this.searchList)]

    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('updateVisible', val)
      }
    }
  },

  created() {

  },
  mounted() {
  this.assembleDataList =  this.assembleData(this.tableData)
    this.getData()
  },
  methods: {
    mergePropertyArrTable(arr) {//合相同指标和指标值
      let newarr = []
      let list = arr

      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.targetName)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.targetName, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }
      return newarr
    },
    onQueryChange(text) {
      if (text == '') {
        this.tableData1 = this.assembleDataList
        return
      }
      this.tableData1 = []

      this.tableData.map(item => {
        if (item.targetName == text) {
          this.tableData1.push(item)
        }
      })
        this.tableData1 =  this.assembleData(this.tableData1)

    },


    //合并函数
    handleSpan({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      // if (columnIndex == 0) {
      //   //计算合并的行数列数
      //   let x = row.mergeCol == 0 ? 0 : row.one
      //   let y = row.mergeCol == 0 ? 0 : 1
      //   return [x, y]
      // }
    if(columnIndex == 0) {
          //计算合并的行数列数
          let x = row.mergeColumn == 0 ? 0:row.mergeColumn
          let y = row.mergeColumn == 0 ? 0:1
          return [x, y]
      }

    },
    getData() {
        this.tableData.sort(function (a, b) {
          return b.index < a.index ? 1 : -1
        })
      this.tableData1 = this.assembleData(this.tableData)
    },
    //函数处理

    assembleData(array) {
    //深拷贝
    let data = JSON.parse(JSON.stringify(array))
      let names = []
      //筛选出不重复的 name值,将其放到 names数组中
      data.forEach(e => {
        if (!names.includes(e.index)) {
          names.push(e.index)
        }
      })
      let nameNums = []
      //将names数组中的 name值设置默认合并0个单元格,放到 nameNums中
      names.forEach(e => {
        nameNums.push({ index: e, num: 0 })
      })
      //计算每种 name值所在行需要合并的单元格数
      data.forEach(e => {
        nameNums.forEach(n => {
          if (e.index == n.index) {
            n.num++
          }
        })
      })
      //将计算后的合并单元格数整合到 data中
      data.forEach(e => {
        nameNums.forEach(n => {
          if (e.index == n.index) {
            if (names.includes(e.index)) {
              e.mergeColumn = n.num
              //删除已经设置过的值(防止被合并的单元格进到这个 if 语句中)
              names.splice(names.indexOf(n.index), 1)
            } else {
              //被合并的单元格设置为 0
              e.mergeColumn = 0
            }
          }
        })
      })
      //将整理后的数据交给表格渲染
      return data
      // this.data1 = data
    }

  }
};
</script>

<style scoped lang="scss">
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
</style>
