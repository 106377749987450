<template>
  <div>

    <div class="title-box" v-show="showTop">
      <Row>
        <Col span="8">
        <span style="margin-right: 8px;">区域</span>

        <el-select v-model="areaValue" style="width: 100px;" @change="areaChange" placeholder="请选择活动区域">
          <el-option v-for="item in areaList" :value="item.value" :label="item.label" :key="item.value"> {{ item.label
          }}</el-option>

        </el-select>
        <el-button type="primary" class="bit" @click="selectProvince" v-show="areaValue == 1">选择省份</el-button>
        <el-button type="primary" class="bit" @click="selectCity" v-show="areaValue == 2">选择城市</el-button>
        <Poptip trigger="hover" width="335" placement="bottom" content="content">
          <!-- <span class="city-text" v-show="areaValue == 1||areaValue == 2">{{ cityText }} <span v-show="cityNum">({{ cityNum }})</span></span> -->
          <span class="city-text" v-show="areaValue == 1">{{ provinceText }} <span v-show="provinceNum">({{ provinceNum
          }})</span></span>
          <span class="city-text" v-show="areaValue == 2">{{ townText }} <span v-show="townNum">({{ townNum
          }})</span></span>
          <div slot="content">
            <div class="slot-box" v-show="areaValue == 1">
              <span class="province" v-for="item in tipProvince" :key="item">{{ item }}</span>
            </div>
            <div class="slot-box" v-show="areaValue == 2">
              <span v-for="item in tipCity" :key="item">{{ item }}</span>
            </div>
          </div>
        </Poptip>
        </Col>
        <Col span="8" style="display: flex; align-items: center;">
        <span style="margin-right: 8px; min-width: 30px;">周期</span>
        <!-- <Select v-model="cycleValue" style="width:70px" @on-change="cycleChange">
          <Option v-for="item in cycleList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select> -->
        <el-select v-model="cycleValue" style="min-width: 80px;margin-right: 16px;" @change="getToolTypeDetail"
          placeholder="请选择周期">
          <el-option style="min-width: 80px;" v-for="item in cycleList" :value="item.value" :label="item.label"
            :key="item.value"> {{ item.label
            }}</el-option>
          <!-- <el-option label="日度" value="date"></el-option>
          <el-option label="月度" value="month"></el-option>
          <el-option label="季度" value="quarter"></el-option>
          <el-option label="年度" value="year"></el-option> -->
        </el-select>
        <div class="date-select-box" v-if="cycleValue === 'month' || cycleValue === 'year' || cycleValue === 'date'">
          <el-date-picker style="width: 156px" v-model="startTime" :clearable="false" :picker-options="pickerOptions"
            @change="startTimeChange" :type="cycleValue" :value-format="valueFormat" :placeholder="`选择时间`">
          </el-date-picker>
          <div> — </div>
          <el-date-picker style="width: 156px" v-model="endTime" :clearable="false" :picker-options="pickerOptions"
            @change="endTimeChange" :type="cycleValue" :value-format="valueFormat" :placeholder="`选择时间`">
          </el-date-picker>
        </div>
        <div class="date-select-box" v-else-if="cycleValue === 'quarter'">
          <QuarterSelect :textValue="startQuarter" @getQuarterValue="getStartQuarterValue"></QuarterSelect>
          <div>
            —
          </div>
          <QuarterSelect :textValue="endQuarter" @getQuarterValue="getEndQuarterValue"></QuarterSelect>
        </div>
        </Col>

        <Col span="7" offset="1" class="target-box">
        <span class="target-text" style="margin-right: 8px;">指标</span>
        <el-button type="primary" class="bit" @click="openIndexGroupModal">选择指标</el-button>
        <span class="city-text"> 已选择{{ indicatorsData.length }}个指标</span>
        </Col>

      </Row>
      <Row style="margin-top: 25px;">

        <Col span="24">
        <span style="line-height: 26px; margin-right: 10px;"> 我的指标组({{ indexGroup.length + 1 }}/6)</span>

        <el-tag @click="getDefaultIndicator" type='info' size="small" class="tag">默认指标</el-tag>
        <el-tag @click="getIndicator(item.params)" closable size="small" type='info' class="tag"
          v-for="item in indexGroup" :key="item.id" @close="groupClose(item)">{{ item.templateName }}</el-tag>


        </Col>
      </Row>
    </div>
    <div class="table-box" :style="{ height: tableBoxHeight + 'px' }">
      <Row :gutter="16" index="" v-show="showTop" style="margin-bottom: 24px;">
        <Col span="12" offset="12" justify="end" style="display:flex; justify-content: flex-end ;align-items: center;">
        <Icon type="ios-alert-outline" style="margin-right: 7px;" />
        <span style="line-height: 40px;margin-right: 30px;" @click="openDatedes">数据说明</span>
        <el-button type="primary" v-show="transpose" style="width:148.45px" @click="transposeTable">转置</el-button>
        <ComfirmButton v-show="!transpose" ref='SelectFormType' size='large' :maskClosable='true' :width='1350'
          :footerHide='true' Icon='md-arrow-dropdown' type='default' info='表格展现方式'>
          <div slot="content">
            <Row class="FormTypeBox">
              <Col v-for="i in 4" :key="i" class="item">
              <div @click="selectFormType(i)" :class="['child', 'selectBox', formType == i ? 'active' : '']"></div>
              <div @click="selectFormType(i)"
                :class="['child', 'viewBox', `viewBox-${i}`, formType == i ? 'active' : '']">
                <div class="mask"></div>
              </div>
              </Col>
            </Row>
          </div>
        </ComfirmButton>
        <el-button style="margin-left: 30px;" type="primary" @click="downloadExcel" icon="el-icon-download">下载数据
        </el-button>

        </Col>


      </Row>

      <Row :gutter="16">
        <Col span="2" offset="22">
        <Tooltip class="openNav-img" placement="bottom" content="还原" v-if="!showTop">
          <img style="transform: rotate(180deg)" @click="showTop = true" src="@/assets/image/closeNav.png" />
        </Tooltip>
        <Tooltip class="closeNav-img" content="全屏展示表格" v-else>
          <img @click="showTop = false" src="@/assets/image/closeNav.png" />
        </Tooltip>
        </Col>
        <Col span="24" style="max-height: 550px;">


        <Table ref="table" :height="TableHheight" v-loading="TableLoading" border :columns="tableColumns"
          :data="tableData">
        </Table>
        </Col>
      </Row>
    </div>
    <Modal style="z-index: 2500 !important;" v-model="indexGroupModal" :mask-closable="false" @on-ok="ok" width="1333"
      class="group-modal">
      <div>
        <Row style="margin-top:30px">
          <Col span="4" style="line-height: 40px;">
          <span>已选区域：
            <span style=" color:#0093DB">
              {{ areaLable }}

            </span>
          </span>
          <span style="margin-left: 5px;">已选周期：
            <span style=" color:#0093DB">
              {{ cycleLable }}

            </span>
          </span>
          </Col>
          <Col span="6" offset="14">
          <el-popover placement="bottom" width="290" title="设置指标组名称" v-model="tempVisible">
            <el-input v-model.trim="tempName" v-if="indexGroup.length < 5" placeholder="请输入指标组名称" maxlength="10"
              @input="tempNameInput" show-word-limit></el-input>
            <span style="color: #FF5151;" v-show="noTempName">请输入指标组名称</span>
            <span style="color: #FF5151;" v-show="tempNameSame">已存在相同名称指标组，请修改名称</span>
            <div v-if="indexGroup.length == 5" style="text-align: center;">指标组已达上限，无法保存</div>

            <div style="text-align: center; margin-top: 10px;">

              <el-button type="primary" @click="addTemplate">确定</el-button>
            </div>
            <el-button class="bit" style=" width: 154px;" slot="reference">保存为我的指标组</el-button>
          </el-popover>
          <!-- <el-button class="bit" style=" width: 154px;">保存为我的指标组</el-button> -->
          <el-button type="primary" @click="indexGroupSave" class="bit" style="margin-left: 16px; width: 154px;">确定
          </el-button>

          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24" class="box-body">
          <div>
            <div class="title">1.选择分类</div>
            <div class="left-box">
              <!-- <Input v-model="value" style="width: 100%" /> -->
              <el-input placeholder="搜索" @input="initData" prefix-icon="el-icon-search" v-model="classSearch" clearable>
              </el-input>
              <!-- <Button type="primary" class="bit" style="margin-left: 16px;">搜索</Button> -->
              <Tabs :value="tabsValue" @on-click="tabClick">
                <TabPane label="常用指标" name="1">
                  <div class="tree-tip" v-show="threeList.length == 0">暂无符合条件结果</div>

                  <div class="leftThree" v-show="threeList.length > 0">

                    <Tree :data="threeList" expand expand-node :render="renderContent"></Tree>
                  </div>
                </TabPane>
                <TabPane label="全部指标" name="0">
                  <div class="tree-tip" v-show="threeList.length == 0">暂无符合条件结果</div>

                  <div class="leftThree" v-show="threeList.length > 0">
                    <Tree :data="threeList" expand expand-node :render="renderContent"></Tree>
                  </div>

                </TabPane>
              </Tabs>
            </div>
          </div>
          <div>
            <div class="title">2.选择指标</div>
            <div class="center-box">
              <div>
                <div class="title">
                  <span style="float: right;" @click="chooseALL" v-show="isChooseAllTargeet">全选</span>
                </div>
                <div class="chose-box">
                  <div v-show="!isChooseTargeet && targeetListInclude" class="chose-tip">请先选择分类</div>
                  <div v-show="!isChooseAllTargeet && isChooseTargeet" class="chose-tip">已选择该分类所有指标</div>
                  <div class="item" v-show="!item.isSelected" @click="chooseTargeet(item, index)"
                    v-for="(item, index) in targeetList" :key="item.title">
                    <Poptip trigger="hover">
                      <span>{{ item.title }}</span>
                      <div slot="content">
                        <span style="color: #515a6e;"> {{ item.title }}</span>
                      </div>
                    </Poptip>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div>
            <div class="title">已选指标：<span style="color: #0093DB;"> {{ indicatorsData.length }}</span>/100</div>
            <div class="right-box">
              <div class="title">
                <Poptip style="float: right;" width="200" placement="bottom" cancel-text="我再想想" confirm
                  title="确定清空已选指标？" @on-ok="clearOk">
                  <span v-show="indicatorsData.length !== 0">
                    清空
                  </span>
                </Poptip>

              </div>
              <div class="table">
                <Table height="400" ref="tagetTable" :columns="indicatorsColumns" :data="indicatorsData">
                  <template slot-scope="{ row }" slot="name">
                    <strong>{{ row.name }}</strong>
                  </template>
                  <template slot-scope="{ row, index }" slot="action">
                    <Icon @click="remove(row, index)" :size="20" style="cursor: pointer;" color="#0093DB"
                      type="ios-close-circle-outline" />
                  </template>
                </Table>
              </div>

            </div>
          </div>
          </Col>
          <!-- <Col span="5">
          <div>1.选择指标</div>
          <div class="center-box">
          </div>
          </Col>
          <Col span="13">
          <div>已选指标：0/100</div>
          <div class="right-box">
          </div>
          </Col> -->
        </Row>
      </div>

      <div slot="footer">

      </div>
    </Modal>

    <Modal v-model="cityModal" @on-visible-change="cityModalChange" :mask-closable="false" title="选择城市" width="752">
      <div class="city-modal">
        <div v-show="!checkAll" class="select-all"> <span @click="selectAll">全选</span> </div>
        <div v-show="checkAll" class="select-all"> <span @click="selectAll">取消全选</span> </div>
        <div style="height: 450px;overflow: scroll;">
          <CheckboxGroup v-model="checkAllCity">
            <div v-for="item in allCityList" class="check-box" :key="item.provinceName">
              <span class="check-title">{{ item.provinceName }}</span>
              <div class="check">
                <!-- <Checkbox v-if="item.provinceName == '广东省'" class="check-label" :value="checkAllGD"
                  :indeterminate="indeterminate" style="margin-right: 42px;color: #0093DB;"
                  @click.prevent.native="handleCheckGD">
                  全选
                </Checkbox>
                  <Checkbox v-if="item.provinceName == '直辖市'" label="twitter" class="check-label" :value="checkAllCrown"
                  :indeterminate="indeterminateCrown" style="margin-right: 42px;color: #0093DB;"
                  @click.prevent.native="handleCheckCrown">
                  全选
                </Checkbox> -->
                <el-checkbox v-if="item.provinceName == '广东省'" class="check-label"
                  style="margin-right: 38px;color: #0093DB;" @click.prevent.native="handleCheckGD" v-model="checkAllGD">
                  全选</el-checkbox>
                <el-checkbox v-if="item.provinceName == '直辖市'" class="check-label"
                  style="margin-right: 38px;color: #0093DB;" @click.prevent.native="handleCheckCrown"
                  v-model="checkAllCrown">全选</el-checkbox>



                <Checkbox :label="chid.cityName" class="check-label" v-for="chid in item.cityList" :key="chid.cityName">
                </Checkbox>
              </div>
            </div>
          </CheckboxGroup>
        </div>

      </div>
      <div slot="footer" class="footer">
        <span class="foot-text">已选择 <span style="color:#FF4343 ;">{{ checkAllCity.length }}</span> 个城市</span>
        <Button type="primary" class="foot-bit" @click="ok">确定</Button>
        <Button class="foot-bit" @click="cancle">取消</Button>
      </div>
    </Modal>
    <Modal v-model="provinceModal" @on-visible-change="provinceModalChange" :mask-closable="false" title="选择省份"
      width="660">
      <div class="city-modal" style="height:358px">
        <div style="overflow: scroll;">
          <CheckboxGroup v-model="checkAllCityProvince">
            <div class="check-box">
              <div class="check">
                <Checkbox class="check-label" :value="checkAllProvince" :indeterminate="indeterminateProvince"
                  style="width: 140px; margin-right: 5px;color: #0093DB;" @click.prevent.native="handleCheckProvince">
                  全选
                </Checkbox>
                <Checkbox style="width: 140px; margin-right: 5px;" v-for="item in provinceList" :key="item.province"
                  :label="item.provinceName" class="check-label">
                </Checkbox>
              </div>
            </div>
          </CheckboxGroup>
        </div>

      </div>
      <div slot="footer" class="footer" style="justify-content: center;">
        <Button type="primary" class="foot-bit" @click="okProvince">确定</Button>
        <Button class="foot-bit" @click="cancleProvince">取消</Button>
      </div>
    </Modal>
    <Datades :visible="dataDesVisible" :tableData="datadesTable" @updateVisible="updateVisible"></Datades>
    <BarCharts :visible="barChartsVisible" :echarttsData="BarChartsData" :title="BarChartsTitle"
      @updateVisible="updateBarChartsVisible"></BarCharts>
    <PieCharts :visible="pieChartsVisible" :echarttsData="PieChartsData" :title="PieChartsTitle"
      @updateVisible="updatePieChartsVisible"></PieCharts>
  </div>
</template>

<script>
import {
  postEcharts
} from "@/api/mapView";
import {
  getTargetTree,
  getArea,
  postTableList,
  postTemplate,
  getGroup,
  getTemplate,
  getTimePeriod,
  postExplain
} from "@/api/dataSearch";
import store from '@/store/index.js';

import {
  downloadTableData,
  formatJson
} from "@/utils/index";
import QuarterSelect from '@/views/economics/components/QuarterSelect'
import Datades from '@/views/economics/components/Datades'//数据说明组件
import BarCharts from '@/views/economics/components/BarCharts'//柱状图展示组件
import PieCharts from '@/views/economics/components/PieCharts'//饼图展示组件
import ComfirmButton from '@/components/Comfirm.vue'
import dataReports from "@/mixins/dataReports";

export default {
  components: {
    QuarterSelect,
    ComfirmButton,
    Datades,
    BarCharts,
    PieCharts,
  },
  mixins: [dataReports],
  data() {
    return {
      pickerOptions: {                                 //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      recursionTreeList: [],//树形结构数据
      recursionName: '',//递归中的零时变量
      chooseClassData: {},//选择的指标--选择指标弹框中左侧点击
      TableLoading: false,//表格加载loading
      isChooseTargeet: false, //是否选择了目标
      areaNames: ['广州市'],//传给后台的地区名字
      BarChartsData: {//柱状图数据
        title: '',
        dataX: [],
        dataY: [],
        unit: '',
        area: '',
      },
      PieChartsData: {//饼状图数据
        title: '',
        dataList: [],
      },
      startQuarter: '',//开始季度
      endQuarter: '',//结束季度
      transposeType: false,//转置类型
      BarChartsTitle: '',//柱状图标题
      PieChartsTitle: '',//饼状图标题
      datadesTable: [],//数据说明-表格数据
      indicatorsDataStyle: [],//格式化后的指标数据
      tempName: '',//模板名称
      tempNameSame: false,//模板名称是否重复
      noTempName: false,//模板名称是否存在
      tempVisible: false,//模板弹窗显示隐藏
      tabsValue: '1',//tab选项卡的值
      clearAllVisible: false,
      isCommon: 1,// 指标数是否常用  0 否 1 是
      classSearch: '',//指标搜索输入框值
      globalTreeTitle: '',// 当前点击的指标数的指标
      targeetList: [//已选择树

      ],
      threeList: [//指标树结构
      ],
      monthValue: null,
      // transpose: false,//是否转置 按钮
      showTop: true,//展开
      dataDesVisible: false,//数据说明弹框显示
      barChartsVisible: false,//柱状图显示
      pieChartsVisible: false,//饼图显示
      startTime: null,//开始时间
      endTime: null,//结束时间
      indexGroup: [//指标组

      ],
      areaValue: 2,
      areaList: [
        { label: "全国", value: 0 },
        { label: "省份", value: 1 },
        { label: "城市", value: 2 },
      ],
      cycleValue: 'month',

      cycleList: [
        { label: "日度", value: "date" },
        { label: "月度", value: "month" },
        { label: "季度", value: "quarter" },
        { label: "年度", value: "year" },
      ],
      allCityList: [],//城市列表
      provinceList: [
        {
          province: "广东",
          provinceCode: "4400",
          provinceName: "广东省",
          cityList: [
            {
              city: "广州",
              cityCode: "4401",
              cityName: "广州市",
            },
            {
              city: "潮州",
              cityCode: "4451",
              cityName: "潮州市",
            },
          ],
        },
        {
          province: "安徽",
          provinceCode: "4400",
          provinceName: "安徽省",
          cityList: [
            {
              city: "合肥",
              cityCode: "4401",
              cityName: "合肥市",
            },
            {
              city: "测试",
              cityCode: "4451",
              cityName: "测试市",
            },
          ],
        },
      ],
      indicatorsColumns: [//选择指标弹框中的表头
        {
          title: '序号',
          key: 'num'
        },
        {
          title: '分类',
          ellipsis: true,
          tooltip: true,
          key: 'fname'
        },
        {
          title: '指标名称',
          ellipsis: true,
          tooltip: true,
          key: 'title'
        },
        {
          title: '单位',
          key: 'unit'
        },
        {
          title: '删除',
          slot: 'action'
        },

      ],
      indicatorsData: [//选择指标弹框中的表格数据
        // {
        //   name: 'John Brown',
        //   age: '房地产开发投资情况',
        //   address: '房地产开发投资',
        //   date: '2016-10-03'
        // },
      ],
      tableColumns: [

        {
          title: '分类',
          slot: 'date',
          fixed: 'left',
          width: 100,
          children: [
            {
              title: '指标',
              key: 'date1',
              fixed: 'left',
              width: 100,
              children: [
                {
                  title: '单位',
                  key: 'date',
                  fixed: 'left',
                  width: 100,
                  children: [
                    {
                      title: '地区',
                      key: 'date',
                      fixed: 'left',
                      width: 200,

                    },
                  ]
                },
              ]
            },
          ]
        },



      ],
      originalData: [//表格原始数据

      ],
      tableData: [
        {
          hanzu: 'John Brown',
          age: '房地产开发投资情况',
          'oneColumns0': 1,
          date: '2016-10-03'
        },
        {
          name: 'Jim Green',
          age: '房地产开发投资情况',
          address: '土地成交价款',
          date: '2016-10-01'
        },
        {
          name: 'Joe Black',
          age: '房地产开发企业土地...',
          address: '施工房屋面积',
          date: '2016-10-02'
        },
        {
          name: 'Jon Snow',
          age: '房地产开发企业土地...',
          address: '竣工房屋面积',
          date: '2016-10-04'
        }
      ],

      cityNum: null,
      indeterminate: true,
      indeterminateCrown: true,
      indeterminateProvince: true,
      checkAll: false,
      checkAllProvince: true,
      checkAllCityProvince: ['广东省'],
      tipProvince: ['广东省'],//列表第一行 提示框中的省份
      tipCity: ['广州市'],//列表第一行 提示框中的城市
      provinceText: '', //省份选择框默认文字
      townText: '', //城市选择框默认文字
      provinceNum: null, //数量
      townNum: null, //数量
      allProvinceName: [],//所有省份名字
      checkAllGD: false,
      checkAllCrown: false,
      checkAllGroup: ['香蕉', '西瓜'],
      checkAllCity: ['广州市'],//存放选中的城市
      allCity: [],//60城市
      GDCity: [],//广东省的城市
      CrownCity: [],//直辖市城市
      cityText: '',
      cityModal: false,
      provinceModal: false,
      indexGroupModal: false,//指标组弹框

    };
  },
  watch: {

    checkAllCity(val) {//城市数组变化
      this.cityText = this.dealCityText('city');
      this.cityNum = val.length
    },
    checkAllCityProvince(val) {//省份数组变化
      this.cityText = this.dealCityText('province');
      this.cityNum = val.length
    },
    checkAllCrown(val) {//直辖市全选-监听
    },
    indeterminateCrown(val) {//直辖市全选-监听
    },
    areaValue(val) {
      if (val == 1) {
        this.cityText = this.dealCityText('province');
        this.cityNum = this.checkAllCityProvince.length
        this.checkAllCityProvince = ['广东省']
        this.tipProvince = ['广东省']
        this.provinceNum = this.tipProvince.length
        this.provinceText = this.dealCityText('province');
        this.areaNames = this.checkAllCityProvince
      }
      if (val == 2) {
        this.checkAllCity = ['广州市']
        this.tipCity = ['广州市']
        this.townNum = this.tipCity.length
        this.townText = this.dealCityText('city');
        this.areaNames = this.checkAllCity
        this.cityText = this.dealCityText('city');
        this.cityNum = this.checkAllCity.length
      }
      // this.getTableData()


    },
  },
  computed: {
    tableBoxHeight() {
      //比TableHheight 高 20
      // return Number(this.TableHheight)  + 100;
      if (this.showTop == true) {
        return 650;
      } else {
        return 800;
      }
    },

    TableHheight() {//: '550', //表格高度
      //根据 showTop 值返回不同的高度
      if (this.showTop == true) {
        return '550';
      } else {
        return '750';
      }

    },
    targeetListInclude() {
      //检测数组targeetList中的isSelected是否为真，返回布尔值
      let flag = true;
      //使用数组 some方法
      this.targeetList.some(item => {
        if (item.isSelected == true) {
          flag = false;
        }
      });
      return flag;



    },
    isChooseAllTargeet() {
      let isChooseAll = false
      this.targeetList.forEach(item => {
        if (item.isSelected == false) {
          isChooseAll = true;
        }
      })
      return isChooseAll

    },
    transpose() {//是否显示转置按钮

      return this.areaNames.length == 1 || (this.startTime == this.endTime)
    },


    areaLable() {//选择区域名字
      let str = ''
      this.areaList.map(item => {
        if (item.value == this.areaValue) {
          str = item.label
        }
      })
      return str
    },
    cycleLable() {//时间类型域名字
      let str = ''
      this.cycleList.map(item => {
        if (item.value == this.cycleValue) {
          str = item.label
        }
      })
      return str
    },
    timeType() {
      return "year"
      // return this.formItem.timeSelect == "quarter" ?
      // 	"year" :
      // 	this.formItem.timeSelect;
    },
    valueFormat() {//时间格式
      let type = 'yyyy-MM-dd'
      if (this.cycleValue == 'month') {
        type = 'yyyy-MM'
      }
      if (this.cycleValue == 'year') {
        type = 'yyyy'
      }
      if (this.cycleValue == 'date') {
        type = 'yyyy-MM-dd'
      }
      return type

    },
    formType() {
      return this.$store.state.reHouseEnterprise.formType
    },
    // cityText(){
    // let str = []
    // if (this.checkAllCity.length >= 3) {
    // str = this.checkAllCity.slice(0,3)
    // str = str.join("、") + '...'
    // }else{
    // str = this.checkAllCity.join("、")
    // }
    // return str

    // },

  },
  created() {

  },
  mounted() {

    if (this.$route.query.searchVal) {
      this.classSearch = this.$route.query.searchVal
      this.tabsValue = '0'
      this.openIndexGroupModal()
      this.TableLoading = false;

    } else {
      this.TableLoading = true;

    }

    this.getCityData()
    this.getTimePeriodList()
    this.getTemplateList()
    this.cityText = this.dealCityText('city');
    this.cityNum = this.checkAllCity.length
    this.townText = this.dealCityText('city');
    this.townNum = this.tipCity.length
  },
  methods: {
    async getTableData() {//获取表格数据

      let type = 0
      switch (this.cycleValue) {
        case 'date':
          type = 0
          break;
        case 'month':
          type = 1
          break;
        case 'quarter':
          type = 2
          break;
        case 'year':
          type = 3
          break;

        default:
          break;
      }

      const params = {
        areaNames: this.areaNames,//区域名字
        areaType: this.areaValue,//区域类型
        // startDate: '2021-01',//开始时间
        // endDate: '2021-12',//结束时间
        startDate: this.startTime,//开始时间
        endDate: this.endTime,//结束时间
        targets: this.indicatorsDataStyle //指标

        ,
        timePeriod: type//周期类型
      }
      let res = await postTableList(params)
      this.TableLoading = false;
      res.map(item => {
        if (!item.targetValue) {
          item.targetValue = '--'
        }
        return item
      })
      this.getExplain()

      //深拷贝一份数据
      this.originalData = JSON.parse(JSON.stringify(res))

      this.tableData = res
      if (this.transpose) {
        if (this.startTime == this.endTime && this.areaNames.length > 1) {//单时间，多周期
          if (this.transposeType) {
            this.singleTimeManyCyclesTableOneTitle(this.originalData)

          } else {
            this.singleTimeManyCyclesTableTowTitle(this.originalData)
          }
        } else {
          if (this.transposeType) {
            this.oneTitle(res)
          } else {
            this.towTitle(res)
          }

          //


        }
      } else {
        switch (this.formType) {
          case 1:
            this.oneTableShow(res)
            break;
          case 2:
            this.towTableShow(res)
            break;
          case 3:

            this.threeTableShow(res)
            break;
          case 4:
            this.fourTableShow(res)
            break;

          default:
            break;
        }

      }
      // this.oneTitle(res)
      // this.towTitle(res)
      // this.oneTableShow(res)
      // this.threeTableShow(res)
    },
    recursionTableColumns(arr) {
      //递归一个数组
      let head = ''

      arr.forEach(item => {
        if (item.children) {
          this.recursionName = this.recursionName + item.title
          this.recursionTableColumns(item.children)
        } else {
          this.recursionName = this.recursionName + item.title
          this.recursionTreeList.push(this.recursionName)
          this.recursionName = ''
        }

      });
      // arr.map(item=>{
      //   if(item.children){
      //     this.recursionTableColumns(item.children)
      //   }else{
      //     this.tableColumns.push(item)
      //   }
      // })

    },

    appendTableHead(datas, rootString, string, resultArr) { //遍历树  获取id数组
      for (let i = 0; i < datas.length; i++) {
        string = rootString + datas[i].title  // 遍历项目满足条件后的操作
        if (datas[i].children) {  //存在子节点就递归
          this.appendTableHead(datas[i].children, string + "\r\n", "", resultArr);
        } else {
          resultArr.push(string)
        }
      }
    },
    downloadExcel() {//下载数据
      let filterVal = [];
      let tHeader = [];
      // this.tableColumns.forEach((item) => {
      //   tHeader.push(item.title);
      //   filterVal.push(item.key);
      // });
      this.tableColumns.forEach((item) => {
        tHeader.push(item.title);
        if (item.children) {
          item.children.forEach(v => {
            filterVal.push(v.key);
          })
        } else {
          filterVal.push(item.key);
        }

      });
      this.recursionTreeList = []
      let arr = []
      let origin = []
      origin.push(this.tableColumns)
      this.appendTableHead(origin[0], "", "", arr)
      // this.recursion(this.tableColumns,null,0,arr)
      this.recursionTableColumns(this.tableColumns)
      let list = []
      this.tableColumns.map(item => {
        item.header = item.title
        if (item.children) {
          item.children.map(v => {
            item.header = item.header + '-' + v.title
            if (v.children) {
              v.children.map(j => {
                item.header = item.header + '-' + j.title
                if (j.children) {
                  j.children.map(k => {
                    item.header = item.header + '-' + k.title
                  })
                } else {

                  list.push(item.header)
                }
              })
            } else {
              list.push(item.header)
            }
            list.push(item.header)
          })
        } else {
          list.push(item.header)
        }
        return item
      })
      list = [...new Set(list)]
      filterVal = [...new Set(filterVal)]
      tHeader = arr
      // tHeader =  this.recursionTreeList
      const data = formatJson(filterVal, this.tableData);

      let pageUri = '#/Insight/MacroEconomy/EconomicStatistics/DataSearch'
      let rowLimited = 0
      let downList = store.state.downListByRple

      downList.map(item => {
        if (item.pageUri == pageUri) {
          rowLimited = item.rowLimited
        }
      })
      if (rowLimited == 0) {
        this.$msg.error({
          text: '暂无下载权限'
        });
        return
      }
      if (data.length > rowLimited) {
        this.$Modal.confirm({
          content: `<p>当前数据已超过下载量最大限制，最多可下载${rowLimited}条数据，确定下载?</p>`,
          onOk: () => {
            this.$btnSysLog(1, 1)

            downloadTableData(tHeader, data, "宏观经济");
          },
          onCancel: () => {
            return
          }
        });
        return
      }
      this.$btnSysLog(1, 1)
      downloadTableData(tHeader, data, "宏观经济");
    },
    changeTimePeriodTyle(item) {//转换类型
      let type = 0
      switch (item) {
        case 'date':
          type = 0
          break;
        case 'month':
          type = 1
          break;
        case 'quarter':
          type = 2
          break;
        case 'year':
          type = 3
          break;

        default:
          break;
      }
      return type
    },
    changeTimePeriodTyle2(item) {//转换类型2
      let type = ''
      switch (item) {
        case 0:
          type = 'date'
          break;
        case 1:
          type = 'month'
          break;
        case 2:
          type = 'quarter'
          break;
        case 3:
          type = 'year'
          break;

        default:
          break;
      }
      return type
    },
    async getTimePeriodList() {//地区-周期-类型
      let res = await getTimePeriod()
      this.areaList = res.map(item => {
        item.label = item.areaType;
        item.value = item.areaTypeCode;
        item.timePeriods.map(v => {
          v.label = v.timePeriodName;
          v.value = this.changeTimePeriodTyle2(v.timePeriodCode);
        })
        return item
      })
      //   timePeriodCode: 0
      // timePeriodName: "日度"
      this.cycleList = this.areaList[2].timePeriods
      this.getGroupList()

    },
    dealQuarteText(item) {//处理季度
      let type = ''
      switch (item) {
        case '03':
          type = '1季度'
          break;
        case '06':
          type = '2季度'
          break;
        case '09':
          type = '3季度'
          break;
        case '12':
          type = '4季度'
          break;

        default:
          break;
      }
      return type
    },
    async getGroupList() {//获取默认指标
      let timePeriod = this.changeTimePeriodTyle(this.cycleValue)
      const params = {
        areaType: this.areaValue,
        timePeriod: timePeriod
      }
      let res = await getGroup(params)
      //    thisendDate: "2021-10"
      // startDate: "2016-12"
      this.startTime = res.startDate
      this.endTime = res.endDate
      if (timePeriod == 2) {
        let startDateYear = res.startDate.split('-')[0]
        let startDateMounth = res.startDate.split('-')[1]
        let endDateYear = res.endDate.split('-')[0]
        let endDateMounth = res.endDate.split('-')[1]
        let startQuarterText = this.dealQuarteText(startDateMounth)
        this.startQuarter = `${startDateYear}年${startQuarterText}`
        let endQuarterText = this.dealQuarteText(endDateMounth)
        this.endQuarter = `${endDateYear}年${endQuarterText}`
      }
      //如果没有时间，就设置为今天的时间
      if (!this.startTime) {
        // this.startTime = this.$moment().format('YYYY-MM')
        let date = new Date()
        let year = date.getFullYear();     //当前
        let month = this.repair(date.getMonth() + 1);//月 //当前
        let day = this.repair(date.getDate());//日
        this.startTime = year + '-' + month + '-' + day
      }
      if (!this.endTime) {
        let date = new Date()
        let year = date.getFullYear();     //当前
        let month = this.repair(date.getMonth() + 1);//月 //当前
        let day = this.repair(date.getDate());//日
        this.endTime = year + '-' + month + '-' + day

        // this.endTime = new Date()
      }

      if (!res.dataExplainRespList) {
        this.indicatorsData = []
        this.tableData = []
        this.tableColumns = []

        return
      }
      this.indicatorsData = res.dataExplainRespList.map((item, v) => {
        item.fname = item.index;
        item.title = item.targetName;
        item.num = v + 1
        return item
      })
      this.indicatorsDataStyle = []
      this.indicatorsData.map(item => {
        let obj = {
          index: item.fname,
          target: item.title,
        }
        this.indicatorsDataStyle.push(obj)
      })
      this.getTableData()

    },
    repair(i) {
      if (i >= 0 && i <= 9) {
        return "0" + i;
      } else {
        return i;
      }
    },
    tempNameInput(value) {//指标名称输入
      this.tempNameSame = false
      this.noTempName = false
      this.indexGroup.map(item => {
        if (item.templateName == value) {
          this.tempNameSame = true
        }
      })
    },
    indexGroupSave() {//选择指标组保存


      if (this.indicatorsData.length == 0) {
        this.$msg.error({
          text: '请先选择指标'
        });
        return
      }

      this.targeetList = []
      this.isChooseTargeet = false

      this.indexGroupModal = false
      this.indicatorsDataStyle = []
      this.indicatorsData.map(item => {
        let obj = {
          index: item.fname,
          target: item.title,
        }
        this.indicatorsDataStyle.push(obj)
      })

      this.getTableData()

    },
    async getTemplateList() {//获取指标组
      const params = {
        route: '/MacroEconomy/EconomicStatistics/DataSearch',//路由
      }
      let res = await getTemplate(params)
      this.indexGroup = res
    },

    async delTemplate(item) {//删除指标组
      const params = {
        isDel: 1,
        id: item.id,
      }
      await postTemplate(params)
      this.getTemplateList()

    },
    async addTemplate() {//保存指标组
      // this.tempName//去除空格

      if (!this.tempName) {
        this.noTempName = true
        return
      }
      if (this.indicatorsData.length == 0) {
        this.$msg.error({
          text: '请先选择指标'
        });
        return
      }
      this.tempName = this.tempName.replace(/\s+/g, '')//去除空格

      const params = {
        isDel: 0,
        params: {//相关参数
          areaValue: this.areaValue,//区域
          cycleValue: this.cycleValue,//周期
          indicatorsData: this.indicatorsData,//指标
        },
        route: '/MacroEconomy/EconomicStatistics/DataSearch',//路由
        templateName: this.tempName,//模板名称
      }
      let res = await postTemplate(params)

      this.tempName = ''
      this.indexGroupSave()
      this.getTemplateList()
      this.tempVisible = false
      this.indexGroupModal = false
    },
    transposeTable() {//转置
      if (this.transposeType) {
        if (this.startTime == this.endTime && this.areaNames.length > 1) {//单时间，多周期
          this.singleTimeManyCyclesTableTowTitle(this.originalData)
        } else {
          this.towTitle(this.originalData)

        }
        this.transposeType = false
      } else {
        if (this.startTime == this.endTime && this.areaNames.length > 1) {//单时间，多周期}
          this.singleTimeManyCyclesTableOneTitle(this.originalData)
        } else {
          this.oneTitle(this.originalData)

        }
        this.transposeType = true
      }
    },
    //选择表格展现形式
    selectFormType(type) {
      this.$refs.SelectFormType.cancle()
      this.spinShow = true
      switch (type) {
        case 1:
          this.oneTableShow(this.originalData)
          break;
        case 2:
          this.towTableShow(this.originalData)
          break;
        case 3:
          this.threeTableShow(this.originalData)
          break;
        case 4:
          this.fourTableShow(this.originalData)
          break;

        default:
          break;
      }
      setTimeout(() => {
        this.$store.commit('reHouseEnterprise/changeState', {
          prop: "formType",
          value: type,
        });
      }, 100)
    },
    async getExplain() {//数据说明
      let type = 0
      switch (this.cycleValue) {
        case 'date':
          type = 0
          break;
        case 'month':
          type = 1
          break;
        case 'quarter':
          type = 2
          break;
        case 'year':
          type = 3
          break;

        default:
          break;
      }
      const params = {
        areaNames: this.areaNames,//区域名字
        areaType: this.areaValue,//区域类型
        // startDate: '2021-01',//开始时间
        // endDate: '2021-12',//结束时间
        startDate: this.startTime,//开始时间
        endDate: this.endTime,//结束时间
        targets: this.indicatorsDataStyle //指标
        ,
        timePeriod: type//周期类型
      }
      let res = await postExplain(params)
      this.datadesTable = res

    },

    oneTableShow(res) {//表格展现形式一
      this.tableColumns = [
        {
          title: '时间',
          key: 'date',
          fixed: 'left',
          width: 200,
        },
        {
          title: '地区',
          key: 'area',
          fixed: 'left',
          width: 100,
        }
      ]

      let arr = JSON.parse(JSON.stringify(this.indicatorsDataStyle))
      let dataRes = JSON.parse(JSON.stringify(res))

      this.tableData = this.mergePropertyByDateTable1(dataRes)

      // let newarr =  this.mergePropertyByIndex(arr, 'target')
      let newarr = this.mergePropertyArrColumnsTable1(res)
      newarr.map((item, index) => {
        let obj = {
          title: item.index,
          unit: item.unit,
          area: item.area,
          target: item.target,
          align: 'center',
          minWidth: 200,
          key: item.target,
          children: [
            {
              title: item.target,
              minWidth: 200,
              align: 'center',
              key: item.target,
              children: [
                {
                  title: item.unit,
                  minWidth: 200,
                  align: 'center',
                  key: item.target,

                }
              ],
              renderHeader: (h, column) => {
                return h("div", [h("div", item.target), h("img", {
                  attrs: {
                    src: require("@/assets/image/Piechart.png"),
                    alt: "",
                    title: "",
                    width: "20px",
                    height: "20px",
                    style: "margin-left: 5px"
                  },
                  on: {
                    click: () => {
                      // this.openBarCharts(column, 'threeType', item)
                      this.openPieCharts(column, 'oneType', item.index, item.target)
                    }
                  },
                },

                )]);

              },
            }
          ],
        }

        this.tableColumns.push(obj)
      })



    },
    towTableShow(res) {//表格展现形式二
      this.tableColumns = [
        {
          title: '分类',
          key: 'index',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '指标',
          key: 'target',
          fixed: 'left',
          width: 300,
          render: (h, params) => {
            // return h('div', {
            //   style: {
            //     width: '200px',
            //     overflow: 'hidden',
            //     textOverflow: 'ellipsis',
            //     whiteSpace: 'nowrap',
            //   },
            //   domProps: {
            //     innerHTML: params.row.target
            //   }
            // })
            return h("span", [h("span", params.row.target), h("img", {
              attrs: {
                src: require("@/assets/image/Barchart.png"),
                alt: "",
                title: "",
                width: "20px",
                height: "20px",
                style: "margin-left: 5px"
              },
              on: {
                click: () => {
                  this.openBarCharts(params, 'towType')
                }
              },
            },
            )]);
          }
        },
        {
          title: '单位',
          key: 'unit',
          fixed: 'left',
          width: 100,
        },
        {
          title: '地区',
          key: 'area',
          fixed: 'left',
          width: 100,
        },
      ]
      let timeTitle = res.map(item => {
        return item.date
      })
      timeTitle = [...new Set([...timeTitle])]  //数组去重
      timeTitle.map((item, index) => {
        let obj = {
          title: item,
          minWidth: 100,
          key: item,

        }
        this.tableColumns.push(obj)
      })
      let dataRes = JSON.parse(JSON.stringify(res))
      this.tableData = this.mergePropertyByDate2(dataRes)
    },
    threeTableShow(res) {//表格展现形式三
      this.tableColumns = [
        {
          title: '分类',
          key: 'index',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '指标',
          key: 'target',
          fixed: 'left',
          width: 200,
          render: (h, params) => {
            // return h('div', {
            //   style: {
            //     width: '200px',
            //     overflow: 'hidden',
            //     textOverflow: 'ellipsis',
            //     whiteSpace: 'nowrap',
            //   },
            //   domProps: {
            //     innerHTML: params.row.target
            //   }
            // })
            return h("span", [h("span", params.row.target), h("img", {
              attrs: {
                src: require("@/assets/image/Piechart.png"),
                alt: "",
                title: "",
                width: "20px",
                height: "20px",
                style: "margin-left: 5px"
              },
              on: {
                click: () => {
                  this.openPieCharts(params, 'towType',)
                }
              },
            },
            )]);
          }
        },

        {
          title: '单位',
          key: 'unit',
          fixed: 'left',
          width: 100,
        }
      ];
      let resArr = this.mergePropertydateCity(res)
      resArr.map((item, index) => {
        let obj = {
          title: item.date,
          width: 200,
          key: item.date + '-' + item.area,
          children: [
            {
              title: item.area,
              minWidth: 200,
              key: item.date + '-' + item.area,
            }
          ],
        }
        this.tableColumns.push(obj)
      })

      let dataRes = JSON.parse(JSON.stringify(res))
      // this.tableData = this.mergePropertyByDate2(dataRes)
      this.tableData = this.mergeByDateAreaTargetTable4(dataRes)




    },
    fourTableShow(res) {//表格展现形式四
      this.tableColumns = [
        {
          title: '分类',
          key: 'date',
          fixed: 'left',
          width: 100,
          children: [
            {
              title: '指标',
              key: 'date',
              fixed: 'left',
              width: 100,
              children: [
                {
                  title: '单位',
                  key: 'date',
                  fixed: 'left',
                  width: 100,
                  children: [
                    {
                      title: '地区',
                      key: 'date',
                      fixed: 'left',
                      width: 200,

                    },
                  ]
                },
              ]
            },
          ]
        },

      ]

      let arr = JSON.parse(JSON.stringify(this.indicatorsDataStyle))
      let dataRes = JSON.parse(JSON.stringify(res))
      // this.tableData = this.mergePropertyByDate(dataRes)
      this.tableData = this.mergePropertyArrDataTable3(dataRes)
      // let newarr = this.mergePropertyByIndex(dataRes, 'target')
      let newarr = this.mergePropertyArrColumnsTable3(res)
      // let newarr = res
      newarr.map((item, index) => {
        let obj = {
          title: item.index,
          unit: item.unit,
          area: item.area,
          target: item.target,
          align: 'center',
          width: 200,
          key: item.target + '' + item.area,
          children: [
            {
              title: item.target,
              minWidth: 200,
              align: 'center',
              key: item.target + '' + item.area,
              children: [
                {
                  title: item.unit,
                  width: 200,
                  align: 'center',
                  key: item.target + '' + item.area,
                  children: [
                    {
                      title: item.area,
                      minWidth: 200,
                      align: 'center',
                      key: item.target + '' + item.area,

                    }
                  ],
                }
              ],
              renderHeader: (h, column) => {
                return h("div", [h("div", item.target), h("img", {
                  attrs: {
                    src: require("@/assets/image/Barchart.png"),
                    alt: "",
                    title: "",
                    width: "20px",
                    height: "20px",
                    style: "margin-left: 5px"
                  },
                  on: {
                    click: () => {
                      this.openBarCharts(column, 'threeType', item)
                    }
                  },
                },

                )]);

              },
            }
          ],
        }

        this.tableColumns.push(obj)
      })


    },
    towTitle(res) {//转置-第二种表头
      let isManycycles = false //是否多周期
      let imgSrc = null //图标地址
      if (this.startTime == this.endTime) {
        imgSrc = require("@/assets/image/Piechart.png")
        isManycycles = false
      } else {
        imgSrc = require("@/assets/image/Barchart.png")

        isManycycles = true
      }
      this.tableColumns = [
        {
          title: '分类',
          key: 'index',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '指标',
          key: 'target',
          fixed: 'left',
          width: 200,
          render: (h, params) => {
            // return h('div', {
            //   style: {
            //     width: '200px',
            //     overflow: 'hidden',
            //     textOverflow: 'ellipsis',
            //     whiteSpace: 'nowrap',
            //   },
            //   domProps: {
            //     innerHTML: params.row.target
            //   }
            // })
            return h("span", [h("span", params.row.target), h("img", {
              attrs: {
                src: imgSrc,
                alt: "",
                title: "",
                width: "20px",
                height: "20px",
                style: "margin-left: 5px"
              },
              on: {
                click: () => {
                  if (isManycycles) {
                    this.openBarCharts(params, 'towType')

                  } else {
                    this.openPieCharts(params, 'towType')

                  }
                }
              },
            },
            )]);
          }
        },
        {
          title: '单位',
          key: 'unit',
          fixed: 'left',

          width: 200,
        },

      ]
      let timeTitle = res.map(item => {
        return item.date
      })
      timeTitle = [...new Set([...timeTitle])]  //数组去重

      timeTitle.map((item, index) => {
        let obj = {
          title: item,
          minWidth: 200,
          key: item,
        }
        this.tableColumns.push(obj)
      })
      let dataRes = JSON.parse(JSON.stringify(res))
      this.tableData = this.mergePropertyByDate2(dataRes)
    },
    oneTitle(res) {// 转置-第一种表头
      this.tableColumns = [
        {
          title: '分类',
          key: 'date',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          tooltip: true,
          children: [
            {
              title: '指标',
              key: 'date',
              fixed: 'left',
              width: 200,
              children: [
                {
                  title: '单位',
                  key: 'date',
                  fixed: 'left',
                  width: 200,
                  // children: [
                  //   {
                  //     title: '地区',
                  //     key: 'date',
                  //     fixed: 'left',
                  //     width: 200,

                  //   },
                  // ]
                },
              ]
            },
          ]
        },

      ]
      let isSortable = false //是否排序
      if (this.startTime == this.endTime && this.areaNames.length > 1) {
        isSortable = true
      } else {
        isSortable = false
      }
      let targetTitleArr = []
      this.indicatorsData.map(item => {
        let obj = {
          index: item.fname,
          target: item.title,
          unit: item.unit,
        }
        targetTitleArr.push(obj)
      })
      // let arr = JSON.parse(JSON.stringify(this.indicatorsDataStyle))
      let arr = JSON.parse(JSON.stringify(targetTitleArr))
      let dataRes = JSON.parse(JSON.stringify(res))
      this.tableData = this.mergePropertyByDate(dataRes)
      let isManycycles = false //是否多周期
      let imgSrc = null //图标地址
      if (this.startTime == this.endTime) {
        imgSrc = require("@/assets/image/Piechart.png")
        isManycycles = false
      } else {
        imgSrc = require("@/assets/image/Barchart.png")

        isManycycles = true
      }
      let newarr = this.mergePropertyByIndex(arr, 'target')

      newarr.map((item, index) => {
        let obj = {
          title: item.index,
          align: 'center',
          width: 200,
          key: item.index,
          children: [

          ],
        }
        item.target.map((v,indexv) => {
          let chidObj = {
            title: v,
            minWidth: 250,
            align: 'center',
            sortable: isSortable,
            key: v,

            renderHeader: (h, column) => {
              return h("div", [h("div", v), h("img", {
                attrs: {
                  src: imgSrc,
                  alt: "",
                  title: "",
                  width: "20px",
                  height: "20px",
                  style: "margin-left: 5px"
                },
                on: {
                  click: () => {
                    if (isManycycles) {
                      this.openBarCharts(column, 'oneType', item)

                    } else {
                      this.openPieCharts(column, 'oneType', item.index, v)

                    }
                  }
                },
              },

              )]);

            },
            children: [
              {
                title: item.unitList[indexv],
                key: v,
                minWidth: 200,
                align: 'center',
              }
            ],
          }
          obj.children.push(chidObj)
        })
        this.tableColumns.push(obj)
      })


    },

    singleTimeManyCyclesTableOneTitle(res) { //单时间多周期的表格展示形式一

      this.tableColumns = [
        {
          title: '分类',
          key: 'area',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          tooltip: true,
          children: [
            {
              title: '指标',
              key: 'area',
              fixed: 'left',
              width: 200,
              children: [
                {
                  title: '单位',
                  key: 'area',
                  fixed: 'left',
                  width: 200,
                  // children: [
                  //   {
                  //     title: '地区',
                  //     key: 'date',
                  //     fixed: 'left',
                  //     width: 200,

                  //   },
                  // ]
                },
              ]
            },
          ]
        },

      ]
      let isSortable = false //是否排序
      if (this.startTime == this.endTime && this.areaNames.length > 1) {
        isSortable = true
      } else {
        isSortable = false
      }
      let targetTitleArr = []
      this.indicatorsData.map(item => {
        let obj = {
          index: item.fname,
          target: item.title,
          unit: item.unit,
        }
        targetTitleArr.push(obj)
      })

      let arr = JSON.parse(JSON.stringify(targetTitleArr))
      let dataRes = JSON.parse(JSON.stringify(res))
      this.tableData = this.mergePropertyByDate(dataRes)
      let isManycycles = false //是否多周期
      let imgSrc = null //图标地址
      if (this.startTime == this.endTime) {
        imgSrc = require("@/assets/image/Piechart.png")
        isManycycles = false
      } else {
        imgSrc = require("@/assets/image/Barchart.png")

        isManycycles = true
      }
      let newarr = this.mergePropertyByIndex(arr, 'target')
      newarr.map((item, index) => {
        let obj = {
          title: item.index,
          align: 'center',
          width: 200,
          key: item.index,
          children: [

          ],
        }
        item.target.map((v,indexv) => {
          let chidObj = {
            title: v,
            minWidth: 250,
            align: 'center',
            //  sortable: true,

            key: v,
            renderHeader: (h, column) => {
              return h("div", [h("div", v), h("img", {
                attrs: {
                  src: imgSrc,
                  alt: "",
                  title: "",
                  width: "20px",
                  height: "20px",
                  style: "margin-left: 5px"
                },
                on: {
                  click: () => {
                    if (isManycycles) {
                      this.openBarCharts(column, 'oneType', item)

                    } else {
                      this.openPieCharts(column, 'oneType', item.index, v)

                    }
                  }
                },
              },

              )]);

            },
            children: [
              {
                title: item.unitList[indexv],
                key: v,
                minWidth: 200,
                align: 'center',
                sortable: true,
                sortMethod: function (a, b, type) {
                  a = a == undefined ? -9999999999999999 : a
                  b = b == undefined ? -9999999999999999 : b
                  a = a == '--' ? -8999999999999999 : a
                  b = b == '--' ? -8999999999999999 : b
                  let one = isNaN(Number(a)) ? a : Number(a)
                  let tow = isNaN(Number(b)) ? b : Number(b)

                  if (type === 'desc') {
                    return one > tow ? -1 : 1
                  }
                  else return one > tow ? 1 : -1;
                },
              }
            ],
          }
          obj.children.push(chidObj)
        })
        this.tableColumns.push(obj)

      })
    },
    singleTimeManyCyclesTableTowTitle(res) {//单时间多周期的表格展示形式二
      let isManycycles = false //是否多周期
      let imgSrc = null //图标地址
      if (this.startTime == this.endTime) {
        imgSrc = require("@/assets/image/Piechart.png")
        isManycycles = false
      } else {
        imgSrc = require("@/assets/image/Barchart.png")

        isManycycles = true
      }
      this.tableColumns = [
        {
          title: '分类',
          key: 'index',
          fixed: 'left',
          width: 200,
        },
        {
          title: '指标',
          key: 'target',
          fixed: 'left',
          width: 200,
          render: (h, params) => {
            // return h('div', {
            //   style: {
            //     width: '200px',
            //     overflow: 'hidden',
            //     textOverflow: 'ellipsis',
            //     whiteSpace: 'nowrap',
            //   },
            //   domProps: {
            //     innerHTML: params.row.target
            //   }
            // })
            return h("span", [h("span", params.row.target), h("img", {
              attrs: {
                src: imgSrc,
                alt: "",
                title: "",
                width: "20px",
                height: "20px",
                style: "margin-left: 5px"
              },
              on: {
                click: () => {
                  if (isManycycles) {
                    this.openBarCharts(params, 'towType')

                  } else {
                    this.openPieCharts(params, 'towType')

                  }
                }
              },
            },
            )]);
          }
        },
        {
          title: '单位',
          key: 'unit',
          fixed: 'left',
          width: 200,
        },

      ]
      let timeTitle = res.map(item => {
        return item.area
      })
      timeTitle = [...new Set([...timeTitle])]  //数组去重

      timeTitle.map((item, index) => {
        let obj = {
          title: item,
          minWidth: 200,
          key: item,
        }
        this.tableColumns.push(obj)
      })
      let dataRes = JSON.parse(JSON.stringify(res))
      this.tableData = this.mergePropertyByDate2(dataRes)
      //  this.tableData = this.mergePropertyByIndex( this.tableData ,'area')
      this.tableData = this.tableData.map(item => {
        item[item.area] = item[item.date]
        //  item.area = item[item.date]
        return item
      })
      this.tableData = this.mergeByDateAreaTarget(this.tableData)

    },
    mergeByDateAreaTargetTable4(arr) {//表格展示形式4 处理数据
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        let terp = item.date + '-' + item.area
        const obj = {
          date: item.date,
          index: item.index,
          target: item.target,
          unit: item.unit,
          area: item.area,
        }
        obj[terp] = item.targetValue
        newArr.push(obj)
      }


      let arr2 = this.mergePropertyArr2(newArr)
      let arr3 = this.mergePropertyArr2Table4(arr2)
      return arr3
    },
    mergePropertyArr2Table4(arr) {
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      let map = new Map;
      list.map(item => {
        item.index_target = item.index + '-' + item.target
        return item
      })

      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.index_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.index_target, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }
      return newarr

    },

    mergeByDateAreaTarget(arr) { //按地区-指标-合并
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      let map = new Map;
      list.map(item => {
        item.new_index_target = item.target + '-' + item.index
        return item
      })
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.new_index_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.new_index_target, tmp1)
      }

      for (let [key, value] of map) {

        newarr.push(value)
      }

      return newarr
    },
    mergePropertyArr(arr) {//合相同时间指标和指标值  对应第一种表头
      let newarr = []
      let list = arr
      list.map(item => {
        item.area_date = item.area + '-' + item.date
        return item
      })
      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.area_date)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.area_date, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }

      return newarr
    },
    mergePropertyByDate(arr) {// 表格数据处理 对应第一种表头
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        let terp = item.target
        const obj = {
          date: item.date,
          area: item.area,
        }

        obj[terp] = item.targetValue
        newArr.push(obj)
      }
      //  newArr =
      // return newArr
      let arr2 = this.mergePropertyArr(newArr)
      return arr2
    },
    mergePropertyByDateTable1(arr) {// 表格数据处理 表格显示 对应转置第一种
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        let terp = item.target
        const obj = {
          date: item.date,
          area: item.area,
        }

        obj[terp] = item.targetValue
        newArr.push(obj)
      }
      let arr2 = this.mergePropertyArrTable1(newArr)
      return arr2
    },
    mergePropertyArrTable1(arr) {//合相同时间指标和指标值  对应转置第一种
      let newarr = []
      let list = arr
      list.map(item => {
        item.area_date = item.area + '-' + item.date
        return item
      })
      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.area_date)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.area_date, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }
      return newarr
    },
    mergePropertyArr2(arr) {//合相同时间指标和指标值  对应第二种表头
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      let map = new Map;

      list.map(item => {
        item.area_target = item.area + '-' + item.target
        // let tmp1 = map.get(item.area_target)
        //       tmp1 = Object.assign(item, tmp1)
        //   map.set(item.area_target, item)
        return item
      })
      // for (let i = 0; i <30&&i<list.length; i++) {
      //   let tmp = list[i];
      //   let tmp1 = map.get(tmp.area_target)
      //   tmp1 = Object.assign(tmp, tmp1)
      //   map.set(tmp.area_target, tmp1)


      // }
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.area_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.area_target, tmp1)


      }

      for (let [key, value] of map) {
        //     if(newarr.length>30){
        //   break;
        // }
        newarr.push(value)
      }

      return newarr
    },
    mergePropertydateCity(arr) {//合相同时间指标和城市
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      let map = new Map;

      list.map(item => {
        item.area_target = item.area + '-' + item.date
        // let tmp1 = map.get(item.area_target)
        //       tmp1 = Object.assign(item, tmp1)
        //   map.set(item.area_target, item)
        return item
      })
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.area_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.area_target, tmp1)

      }

      for (let [key, value] of map) {
        newarr.push(value)
      }

      return newarr
    },

    mergePropertyArrColumnsTable1(arr) {//表头处理，对应第一种表头
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      list.map(item => {
        item.index_target = item.index + '-' + item.target
        return item
      })
      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.index_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.index_target, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }

      return newarr
    },
    mergePropertyArrColumnsTable3(arr) {//合相同地区和指标值  对应第三种表头
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      list.map(item => {
        item.area_target = item.area + '-' + item.target
        return item
      })
      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.area_target)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.area_target, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }

      return newarr

    },
    mergePropertyArrDataTable3(arr) {//数据处理  对应第三种表格
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        let terp = item.target + '' + item.area
        const obj = {
          date: item.date,
          area: item.area,
        }

        obj[terp] = item.targetValue
        newArr.push(obj)
      }

      let arr2 = this.mergePropertyArrDataTable3Time(newArr)
      return arr2
    },
    mergePropertyArrDataTable3Time(arr) {
      let newarr = []
      // let list = arr
      //深拷贝一份数据
      let list = JSON.parse(JSON.stringify(arr))
      let map = new Map;
      for (let i = 0; i < list.length; i++) {
        let tmp = list[i];
        let tmp1 = map.get(tmp.date)
        tmp1 = Object.assign(tmp, tmp1)
        map.set(tmp.date, tmp1)
      }
      for (let [key, value] of map) {
        newarr.push(value)
      }
      return newarr
    },
    mergePropertyByDate2(arr) {// 表格数据处理 对应第二种表头
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        let terp = item.date
        const obj = {
          date: item.date,
          index: item.index,
          target: item.target,
          unit: item.unit,
          area: item.area,
        }
        obj[terp] = item.targetValue
        newArr.push(obj)
      }


      let arr2 = this.mergePropertyArr2(newArr)

      return arr2
    },
    mergePropertyByIndex(arr, property) {// 合并相同的项
      const tempIds = [],
        newArr = []
      for (const item of arr) {
        if (!tempIds.includes(item.index)) {
          const obj = { index: item.index, unit: item.unit }
          obj[property] = [item[property]]
          obj['unitList'] = [item.unit]

          newArr.push(obj)
          tempIds.push(item.index)
        } else {
          for (const ele of newArr) {
            if (ele.index === item.index) {
              ele[property].push(item[property])
              ele['unitList'].push(item.unit)
              break
            }
          }
        }
      }
      return newArr
    },
    initData() {//输入框搜索
      this.tabsValue = '0'
      this.getTargetTreeLsist()
    },
    clearOk() {
      this.clearALL()
    },
    clearALL() {//删除所有
      this.indicatorsData.map((row, index) => {
        this.threeList.map(item => {
          item.children.map(chid => {
            chid.children.map(v => {
              if (v.title == row.fname) {
                v.targeetList.map(j => {
                  if (j.title == row.title) {
                    j.isSelected = false

                  }
                })
              }
            })
            return item
          })
        })

      })
      this.indicatorsData = []

    },
    remove(row, index) {//删除已选指标
      this.threeList.map(item => {
        item.children.map(chid => {
          chid.children.map(v => {
            if (v.title == row.fname) {
              v.targeetList.map(j => {
                if (j.title == row.title) {
                  j.isSelected = false
                }
              })
            }
          })
          return item
        })
      })
      this.indicatorsData.splice(index, 1)
      this.indicatorsData.map((v, num) => {
        v.num = num + 1
        return v
      })
      // this.targeetList.push(row)
    },
    chooseTargeet(item, index) {//选择指标
      if (this.indicatorsData.length >= 100) {
        this.$msg.error({
          text: '选择的指标已达上限，最多可选择100个指标'
        });
        return
      }
      for (let i = 0; i < this.indicatorsData.length; i++) {
        const element = this.indicatorsData[i];
        if (item.title == element.title && item.fname == element.fname) {
          this.$msg.error({
            text: '该指标已选择'
          });
          return
        }

      }
      item.isSelected = true
      this.$set(this.targeetList, index, item)

      // this.targeetList.splice(index, 1)

      this.indicatorsData.push(item)
      this.indicatorsData = [...new Set(this.indicatorsData)]
      this.indicatorsData.map((v, num) => {
        v.num = num + 1
        return v
      })
      // 'ivu-table-body'
      //获取类名
      let container = document.getElementsByClassName('ivu-table-body')[1]
      //content 滚动底部
      // 让滚动条始终在最底部
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;


      })
    },
    chooseALL() {//选择所有
      if (this.indicatorsData.length >= 100) {
        this.$msg.error({
          text: '选择的指标已达上限，最多可选择100个指标'
        });
        return
      }
      this.targeetList.map(item => {
        if (!item.isSelected) {
          if (this.indicatorsData.length < 100) {
            item.isSelected = true
            this.indicatorsData.push(item)

          }

        }




        return item
      })
      // this.indicatorsData = new Set( ...this.indicatorsData)

      this.indicatorsData.map((v, num) => {
        v.num = num + 1
        return v
      })
      //表格自动滚动到底部
      let container = document.getElementsByClassName('ivu-table-body')[1]
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;


      })

    },
    chooseClass(data) {//点击左侧指标数
      if (data.level == 2) {
        // data.selected =
        this.chooseClassData = data;
        this.isChooseTargeet = true
        this.targeetList = data.targeetList
        this.targeetList.map(item => {
          this.indicatorsData.map(v => {
            if (item.title == v.title && item.fname == v.fname) {
              item.isSelected = true
            }
          })
        })
        this.threeList = this.threeList.map(item => {
          item.children.map(v => {
            v.children.map(j => {
              if (j.title == data.title) {
                j.selected = !j.selected
              } else {
                j.selected = false
              }

            })
          })
          return item
        })
        this.globalTreeTitle = data.title

      }
    },
    tabClick(value) {//常用-全部切换
      this.isCommon = value
      this.tabsValue = value
      this.getTargetTreeLsist()
    },
    async getTargetTreeLsist() {//获取指标数
      let timePeriod = 0
      switch (this.cycleValue) {
        case 'date':
          timePeriod = 0
          break;
        case 'month':
          timePeriod = 1
          break;
        case 'quarter':
          timePeriod = 2
          break;
        case 'year':
          timePeriod = 3
          break;

        default:
          break;
      }
      const params = {
        q: this.classSearch,
        isCommon: this.isCommon,
        areaType: this.areaValue,
        timePeriod: timePeriod,

      }
      let res = await getTargetTree(params)
      this.threeList = []
      this.threeList = res.targetTreeList.map(item => {
        item.expand = true
        item.children.map(v => {
          v.expand = true;
          v.children.map(j => {
            j.expand = true;
            j.selected = false;
            j.targeetList = j.children
            if (j.title == this.chooseClassData.title) {
              j.selected = true
            }
            if (j.targeetList) {
              j.targeetList.map(k => {
                k.expand = true;
                k.fname = j.title
                k.selected = true
                k.isSelected = false
              })
            }
            j.children = []
          })
        })
        return item
      })
    },
    renderContent(h, {
      root,
      node,
      data
    }) {
      return h(
        "span", {
        style: {
          display: "inline-block",
          width: "100%",
          cursor: "pointer",
        },
        on: {
          click: () => {
            this.chooseClass(data);
          },
        },
      },
        [
          h(
            "Tooltip", {
            props: {
              placement: "top-end",
              transfer: true,
              theme: "light",
            },
          },
            [
              data.title, //控制树形显示的内容
              h(
                "span", {
                slot: "content",
                style: {
                  whiteSpace: "normal",
                },
              },
                data.title //控制Tooltip显示的内容
              ),
            ]
          ),
          h("span", {
            style: {
              display: "inline-block",
              float: "right",
              marginRight: "32px",
            },
          }),
        ]
      );
    },


    updateVisible(value) {//数据说明子方法触发
      this.dataDesVisible = value
    },
    updateBarChartsVisible(value) {//柱状图子方法触发
      this.barChartsVisible = value
    },
    updatePieChartsVisible(value) {//饼图子方法触发
      this.pieChartsVisible = value
    },
    openDatedes() {//打开数据说明弹框
      this.dataDesVisible = true
    },
    async openBarCharts(row, type, itemData) {//打开柱状图弹框
      this.BarChartsData.dataX = []
      this.BarChartsData.dataY = []
      this.tableData.title = ''
      this.BarChartsData.area = ''
      this.BarChartsData.unit = ''
      this.barChartsVisible = true
      if (type == 'oneType') {
        //分割线
        this.BarChartsData.title = row.column.title
        this.BarChartsData.area = this.areaNames
        this.BarChartsData.unit = itemData.unit
        let trem = row.column.title
        this.tableData.sort(function (a, b) {
          return b.date < a.date ? 1 : -1
        })
        this.tableData.map(item => {
          this.BarChartsData.dataX.push(item.date)
          this.BarChartsData.dataY.push(item[trem])
        })
      }
      if (type == 'towType') {
        this.BarChartsData.title = row.row.target
        let obj = row.row
        const { target, date, area, index, unit, _index, _rowKey, area_target, ...obnj2 } = obj//解构赋值
        this.BarChartsData.area = area
        this.BarChartsData.unit = unit
        Object.keys(obnj2).map(item => {
          this.BarChartsData.dataX.push(item)
          this.BarChartsData.dataY.push(obnj2[item])
        })
      }
      let targets = []
      if (type == 'threeType') {
        targets = [
          {
            index: itemData.index,
            target: itemData.target
          }
        ]
        let timePeriodType = this.dateTypeChange(this.cycleValue)
        const params = {
          areaNames: itemData.area,//区域名字
          areaType: this.areaValue,//区域类型
          startDate: this.startTime,//开始时间
          endDate: this.endTime,//结束时间
          targets: targets//指标
          ,
          timePeriod: timePeriodType//周期类型
        }
        let res = await postEcharts(params)
        this.BarChartsData.title = itemData.target
        this.BarChartsData.area = itemData.area
        this.BarChartsData.unit = itemData.unit
        let trem = row.column.title
        this.BarChartsData.dataX = res.dateList
        this.BarChartsData.dataY = res.targetDataList[0].value
      }

    },
    async getPieData(targets) {//获取饼图数据

      let timePeriodType = this.dateTypeChange(this.cycleValue)
      const params = {
        areaNames: this.areaNames,//区域名字
        areaType: this.areaValue,//区域类型
        startDate: this.startTime,//开始时间
        endDate: this.endTime,//结束时间
        targets: targets//指标
        ,
        timePeriod: timePeriodType//周期类型
      }
      let res = await postEcharts(params)

      this.PieChartsData.title = []
      this.PieChartsData.dataList = []
      this.PieChartsTitle = []
      this.pieChartsVisible = true

      this.PieChartsData.title = targets[0].target
      this.PieChartsData.dataList = res.pieTargetDataList
      this.PieChartsTitle = targets[0].target

    },
    async openPieCharts(row, type, index, target) {//打开饼图弹框
      let targets = []
      if (type == 'oneType') {
        targets = [
          {
            index: index,
            target: target
          }
        ]
      }
      if (type == 'towType') {
        targets = [
          {
            index: row.row.index,
            target: row.row.target
          }
        ]
      }
      this.pieChartsVisible = true
      this.getPieData(targets)
    },
    getDefaultIndicator() {
      this.getGroupList()
    },
    getIndicator(item) {
      let obj = JSON.parse(item);
      // this.areaValue = obj.areaValue
      this.cycleValue = obj.cycleValue
      this.indicatorsData = obj.indicatorsData
      this.indicatorsDataStyle = []
      this.indicatorsData.map(item => {
        let obj = {
          index: item.fname,
          target: item.title,
        }
        this.indicatorsDataStyle.push(obj)
      })
      // if (this.areaValue == 1) {
      //   this.areaNames = ['广东省']
      // }
      // if (this.areaValue == 2) {
      //   this.areaNames = ['广州市']
      // }

      // this.areaNames
      if (this.areaValue == obj.areaValue) {
        this.getTableData()

      } else {
        this.areaValue = obj.areaValue
        if (this.areaValue == 1) {
          this.areaNames = ['广东省']
        }
        if (this.areaValue == 2) {
          this.areaNames = ['广州市']
        }
        this.getTableData()

      }
    },
    dateTypeChange(value) {//时间类型切换
      let type = 0
      switch (value) {
        case 'date':
          type = 0
          break;
        case 'month':
          type = 1
          break;
        case 'quarter':
          type = 2
          break;
        case 'year':
          type = 3
          break;

        default:
          break;
      }

      return type
    },

    // 时间类型切换
    getToolTypeDetail(val) {
      this.startTime = null
      this.endTime = null
      this.getGroupList()
      // this.form.time = ''
    },
    // 季度选择
    getStartQuarterValue(value) {
      const { date, year, quarter } = value
      this.startTime = date
      this.getTableData()
    },
    getEndQuarterValue(value) {
      const { date, year, quarter } = value
      this.endTime = date
      this.getTableData()

    },
    openIndexGroupModal() {//选择指标模态框打开
      this.getTargetTreeLsist()
      this.indexGroupModal = true
    },

    groupClose(item) {//指标删除

      this.$Modal.confirm({

        content: `<p>确定删除指标组'${item.templateName}'？</p>`,
        cancelText: '我再想想',
        loading: true,
        onOk: () => {

          this.delTemplate(item)
          this.$Modal.remove();
        }
      });


    },

    cycleChange() {//周期变化

    },
    areaChange(value) {//城市变化
      this.cycleList = this.areaList[value].timePeriods
      this.cycleValue = this.cycleList[0].value
      this.cityText = '';
      this.cityNum = null;
      this.checkAllCity = [];
      switch (value) {
        case 0:
          this.checkAllCity = ['国家']
          break;
        case 1:
          this.checkAllCity = ['广东省']
          this.checkAllCityProvince = ['广东省']
          break;
        case 2:
          this.checkAllCity = ['广州市']
          break;
        default:
          break;
      }
      this.areaNames = this.checkAllCity

      this.getGroupList()
    },
    dealCityText(type) {

      let arr = []
      if (type == 'city') {
        arr = this.tipCity
      } else {
        // arr = this.checkAllCityProvince
        arr = this.tipProvince
      }

      let str = []
      if (arr.length >= 3) {
        str = arr.slice(0, 3)
        str = str.join("、") + '...'
      } else {
        str = arr.join("、")
      }
      if (str.length > 15) {
        str = str.substring(0, 15) + '...'
      }

      return str
    },
    ok() {//点击确定按钮
      if (this.checkAllCity.length == 0) {
        this.$msg.error({
          text: '请选择城市！'
        });
        return
      }
      this.cityText = this.dealCityText('city');
      this.cityNum = this.checkAllCity.length
      this.tipCity = this.checkAllCity
      this.townNum = this.tipCity.length
      this.townText = this.dealCityText('city');
      this.areaNames = this.checkAllCity
      this.getTableData()
      this.cityModal = false
    },
    cancle() {//点击取消按钮
      // this.checkAllCity = []
      // this.cityText = this.dealCityText('city');
      // this.cityNum = this.checkAllCity.length
      // this.areaNames = this.checkAllCity
      this.cityModal = false
      this.checkAllCity = this.tipCity

    },
    cityModalChange(val) {
      if (!val) {
        this.checkAllCity = this.tipCity
      }
    },
    okProvince() {//省份确定按钮
      if (this.checkAllCityProvince.length == 0) {
        this.$msg.error({
          text: '请选择省份！'
        });
        return
      }
      this.cityText = this.dealCityText('province');
      this.cityNum = this.checkAllCityProvince.length
      this.tipProvince = this.checkAllCityProvince
      this.areaNames = this.checkAllCityProvince
      this.provinceNum = this.tipProvince.length
      this.provinceText = this.dealCityText('province');
      this.getTableData()

      this.provinceModal = false
    },
    cancleProvince() {//省份点击取消按钮
      this.checkAllCityProvince = this.tipProvince
      this.provinceModal = false


    },
    provinceModalChange(val) {//省份模态框改变
      if (!val) {
        this.checkAllCityProvince = this.tipProvince
      }
    },
    async getCityData() {
      let res = await getArea()
      this.allCityList = res.provinceList
      //深拷贝一份数据
      let allCity = JSON.parse(JSON.stringify(res.provinceList))
      this.provinceList = []
      allCity.map(item => {
        if (item.provinceName == '直辖市') {
          item.cityList.map(v => {
            v.provinceName = v.cityName
            v.provinceCode = v.cityCode
            this.provinceList.push(v)

          })
        } else {
          this.provinceList.push(item)
        }

      })
      this.allProvinceName = this.provinceList.map(item => {
        return item.provinceName

      })
      res.provinceList.map(item => {
        // this.allCity  =
        item.cityList.map((v) => {
          this.allCity.push(v.cityName)
        })
        if (item.provinceName == '广东省') {
          this.GDCity = item.cityList.map((item) => item.cityName)

        }
        if (item.provinceName == '直辖市') {
          this.CrownCity = item.cityList.map((item) => item.cityName)

        }

      })
      //
      let queryCity = this.$route.query.city
      let queryCityText = queryCity + '市'
      let isQueryCity = this.allCity.includes(queryCity)
      let isQueryCityText = this.allCity.includes(queryCityText)
      if (queryCity) {
        if (isQueryCity || isQueryCityText) {// 从首页跳过来--命中规则
          if (isQueryCity) {
            this.checkAllCity = [queryCity]
            this.areaNames = this.checkAllCity
          } else {
            this.checkAllCity = [queryCityText]
            this.areaNames = this.checkAllCity
          }
          this.tipCity = this.checkAllCity
          this.townNum = this.tipCity.length
          this.townText = this.dealCityText('city');
          this.cityText = this.dealCityText('city');
        } else {
          this.$msg.error({
            text: '匹配不到该城市，请手动选择城市！'
          });
        }
      }


    },
    selectCity() {
      this.cityModal = true;
    },
    selectProvince() {

      this.provinceModal = true;
    },
    selectAll() {
      this.checkAll = !this.checkAll;
      // this.handleCheckAll()
      if (this.checkAll) {
        // this.checkAllGD = true
        this.checkAllCity = this.allCity;
        // this.handleCheckGD()
      } else {
        //  this.checkAllGD = false
        this.checkAllCity = [];
      }
    },
    handleCheckCrown() {//直辖市选择
      // this.checkAllCity =  this.CrownCity
      // if (this.indeterminateCrown) {
      //   this.checkAllCrown = false;
      // } else {
      //   this.checkAllCrown = !this.checkAllCrown;
      // }
      // this.indeterminateCrown = false;
      this.checkAllCrown = !this.checkAllCrown;
      if (this.checkAllCrown) {
        this.checkAllCity = [...this.checkAllCity, ...this.CrownCity]
        this.checkAllCity = [...new Set(this.checkAllCity)]//数组去重
      } else {

        this.checkAllCity = this.checkAllCity.filter(v => {
          if (!this.CrownCity.includes(v)) {
            return v
          }
        });

      }

    },
    handleCheckGD() {
      // if (this.indeterminate) {
      //   this.checkAllGD = false;
      // } else {
      //   this.checkAllGD = !this.checkAllGD;
      // }
      // this.indeterminate = false;
      this.checkAllGD = !this.checkAllGD;
      if (this.checkAllGD) {
        this.checkAllCity = [...this.checkAllCity, ...this.GDCity]
        this.checkAllCity = [...new Set(this.checkAllCity)]//数组去重
      } else {

        this.checkAllCity = this.checkAllCity.filter(v => {
          if (!this.GDCity.includes(v)) {
            return v
          }
        });

      }
    },

    handleCheckProvince() {
      if (this.indeterminateProvince) {
        this.checkAllProvince = false;
      } else {
        this.checkAllProvince = !this.checkAllProvince;
      }
      this.indeterminateProvince = false;

      if (this.checkAllProvince) {
        this.checkAllCityProvince = this.allProvinceName;
      } else {
        this.checkAllCityProvince = [];
      }
    },
    startTimeChange() {//选择开始时间
      if (this.startTime.length === 4) {
        this.startTime = this.startTime + '-12'
      }
      this.getTableData()
    },
    endTimeChange() {//选择结束时间
      if (this.endTime.length === 4) {
        this.endTime = this.endTime + '-12'
      }
      this.getTableData()

    },

  }
};
</script>

<style scoped lang="scss">
@import "@/style/common.scss";


.title-box {
  // width: 100%;
  // height: 120px;
  padding: 16px 24px;
  margin: 16px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #E2E2E2;

  .slot-box {
    display: flex;
    flex-wrap: wrap;

    span {
      width: 75px;
      text-align: left;
    }

    .province {
      width: 110px;
      //  text-align: left;
    }
  }

  .bit {
    // width: 154px;
    margin: 0 20px 0 16px;
  }

  .city-text {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #0093DB;
  }

}

.table-box {

  // width: 1633px;
  // min-height: 636px;
  padding: 16px 24px;
  margin: 16px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #E2E2E2;
}

.footer {
  display: flex;

  .foot-text {
    // float: left;
    width: 110px;
    margin-left: 16px;
    margin-right: 75px;
    line-height: 40px;
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #424242;
  }

  .foot-bit {
    width: 154px;
    height: 40px;
    margin-right: 8px;
  }

}

.city-modal {
  height: 505px;
  // overflow: hidden;
  overflow: scroll;


  .select-all {
    text-align: right;
    color: #0093DB;

    // cursor: pointer;
    span {
      cursor: pointer;

    }

    margin-right: 28px;
    margin-bottom: 10px;
  }

  .check-box {
    display: flex;

    .check-title {
      width: 75px;
      margin-left: 16px;
      margin-right: 5px;
      // margin-right: 24px;
      font-size: 14px;
      // display: flex;
      // flex-wrap: wrap;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #424242;
    }

    .check {
      display: flex;
      // justify-content: space-around;
      width: 650px;
      flex-wrap: wrap;

      .check-label {
        margin-right: 24px;
        margin-bottom: 20px;

      }
    }
  }

}

.tree-tip {
  margin-top: 50%;
  text-align: center;

}

.group-modal {
  .box-body {
    display: flex;

    .title {
      height: 40px;
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

  }

  .left-box {
    width: 295px;
    height: 482px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #E2E2E2;

    .leftThree {
      width: 250px;
      height: 332px;
      overflow: auto;
      margin: 0 0 12px 0;

      .ivu-tree-arrow {
        width: 30px !important;
      }
    }
  }

  .center-box {
    width: 278px;
    height: 482px;
    padding: 16px;
    margin: 0 9px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #E2E2E2;

    .title {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #0093DB;
      cursor: pointer;
    }

    .chose-box {
      overflow: hidden;
      height: 420px;
      overflow-y: scroll;
      overflow-x: scroll;
      word-break: keep-all;
      white-space: nowrap;

      .chose-tip {
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 54%;
        text-align: center;
      }
    }

    .item {
      cursor: pointer;

      &:hover {
        color: #0093DB;

      }
    }
  }

  .right-box {
    width: 710px;
    flex: 1;
    height: 482px;

    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #E2E2E2;

    .title {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #0093DB;
      cursor: pointer;

    }

    .table {
      height: 420px;
      // overflow: hidden;
      // overflow-y: scroll;
    }
  }
}

.date-select-box {
  display: flex;
  justify-content: center;
  align-items: center;

}

.tag {
  margin: 0 5px;
  cursor: pointer;
}

.FormTypeBox {
  width: 1333px;
  padding-bottom: 50px;

  >.item {
    width: 50%;
    font-size: 0;
    text-align: center;
    margin-top: 50px;
    cursor: pointer;

    .child {
      display: inline-block;
      vertical-align: middle;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .selectBox {
      width: 34px;
      height: 34px;
      background-image: url(../../assets/image/finance/select.png);

      &.active {
        background-image: url(../../assets/image/finance/select_active.png);
      }
    }

    .viewBox {
      width: 576px;
      height: 160px;
      margin-left: 20px;
      opacity: 0.5;
      position: relative;

      &.viewBox-1 {
        background-image: url(../../assets/image/finance/searchformType-1.png);
      }

      &.viewBox-2 {
        background-image: url(../../assets/image/finance/searchformType-2.png);
      }

      &.viewBox-3 {
        background-image: url(../../assets/image/finance/searchformType-3.png);
      }

      &.viewBox-4 {
        background-image: url(../../assets/image/finance/searchformType-4.png);
      }

      &.active {
        opacity: 1 !important;

        .mask {
          opacity: 0 !important;
        }

      }

      &:hover {
        opacity: 0.8;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        opacity: 0;
        border-radius: 8px;

        &:hover {
          opacity: 0.3;
        }
      }
    }
  }
}

.openNav-img,
.closeNav-img {
  position: absolute;
  width: 42px;
  height: 16px;
  right: 17px;
  bottom: -1px;
  z-index: 9;
  cursor: pointer;
}

.openNav-img {
  bottom: -15px;
  // transform: rotate(180deg);
}

.tab-img {
  display: flex;
  align-items: center;

  img {
    margin-bottom: 4px;
    cursor: pointer;
  }

}

.target-box {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1400px) {
    justify-content: end;
  }

}

//媒体查询
@media screen and (max-width: 1400px) {
  .target-text {
    display: none;
  }
}

/deep/.ivu-tabs-bar {
  border-bottom: 0px;
}

/deep/.ivu-modal-header {
  border-bottom: none
}

/deep/.ivu-modal-footer {
  border-top: none
}

/deep/.el-date-editor .el-range-separator {
  padding: 0;
}
</style>
