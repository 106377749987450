import { post, get } from "@/utils/http";

// 指标树
export function getTargetTree(params) {
    return get({ url: "/service-insight/macroeconomy/data/target/tree",params });
}
// 60城
export function getArea(params) {
    return get({ url: "/service-insight/macroeconomy/data/area",params });
}
// 表格数据
export function postTableList(params) {
    return post({ url: "/service-insight/macroeconomy/data/report/list",params });
}
// 数据说明
export function postExplain(params) {
    return post({ url: "/service-insight/macroeconomy/data/explain",params });
}
// 获取周期和季度级联关系
export function getTimePeriod(params) {
    return get({ url: "/service-insight//macroeconomy/data/timePeriod",params });
}
// 获取指标组
export function getGroup(params) {
    return get({ url: "/service-insight//macroeconomy/data/default/target/group",params });
}
// 获取指标组
export function getTemplate(params) {
    return get({ url: "/service-insight/land/data/query_template",params });
}
// 保存-删除指标组
export function postTemplate(params) {
    return post({ url: "/service-insight/land/data/is_del_template",params });
}

