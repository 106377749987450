<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">

      <el-row>
        <el-row>
          <el-col :span="24" v-show="options.length > 1">
          <span class="time">时间</span>
            <el-select v-model="timeValue" @change="timeChange" placeholder="请选择" style="width: 100px;">
    <el-option
      v-for="item in options"
      :key="item.date"
      :label="item.date"
      :value="item.date">
    </el-option>
  </el-select>
          </el-col>
        </el-row>
        <el-col :span="24" style="margin-top: 16px;">
          <div :id="echartsHistogramId" class="echartsHistogram"> </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
        title:{
      type:String,
      default:''
    },
  echarttsData:{
    type:Object,
  },


  },
  data() {
    return {

      echartsHistogramId: 'histogram_pie' + new Date().getTime() + Math.floor(Math.random() * 1000),
      // options:[],
      timeValue:'',
  options:[],
      // timeValue:this.echarttsData.dataList[0].date,
  //     pieData:this.echarttsData.dataList[0].pieList,
      // dialogVisible:true
    };
  },
watch:{
  title(val){
    if(val){
    this.options = this.echarttsData.dataList
       this.options.sort(function(a, b) {
          return b.date < a.date ? -1 : 1
        })
    this.timeValue = this.echarttsData.dataList[0].date
    this.pieData = this.echarttsData.dataList[0].pieList
      this.initEchart()
    }
  },
  echarttsData(val){
    this.options = this.echarttsData.dataList
       this.options.sort(function(a, b) {
          return b.date < a.date ? -1 : 1
        })
    this.timeValue = this.echarttsData.dataList[0].date
    this.pieData = this.echarttsData.dataList[0].pieList
      this.initEchart()
  },

},
  computed: {
    dialogTitle(){
    if (this.options.length==1) {
      return this.echarttsData.title + '(' + this.timeValue + ')'

    }else{
   return this.echarttsData.title

    }
    },
    dialogVisible: {
      get() {

        return this.visible
      },
      set(val) {
        this.$emit('updateVisible', val)
      }
    }
  },

  created() {

  },
  mounted() {
    this.pieData = this.echarttsData.dataList[0].pieList

    this.initEchart()
  },
  updated() {//子组件更新时

    this.initEchart()
  },
  methods: {
  timeChange(value){
  let arr = this.echarttsData.dataList
  arr.map(item=>{
  if (value==item.date) {
     this.pieData =item.pieList
  }
  })
    this.initEchart()
  },
    initEchart() {
      let myChart = this.$echarts.init(document.getElementById(this.echartsHistogramId));
      let option;

      option = {
        color: [
          ' #37A2DA',
          '#32C5E9',
          '#67E0E3',
          '#9FE6B8',
          '#FFDB5C',
          '#ff9f7f',
          '#fb7293',
          '#E062AE',
          '#E690D1',
          '#e7bcf3',
          '#9d96f5',
          '#8378EA',
          '#96BFFF'
        ],

                tooltip: {
          trigger: 'item',
          backgroundColor: "rgba(255, 255, 255,0.8)",
          borderColor: '#EDF0F4',
          borderWidth: '0.5',
          // position: ['20%', '20%'],
          textStyle: {
            color: '#333333'
          },
          // formatter:'{b0}: {c0}<br />{b1}: {c1}',
        },
        legend: {
           type: 'scroll',
    orient: 'vertical',
    right: 0,
    top: 20,
    bottom: 20,

        },
        toolbox: {
          show: true,

        },
        series: [
          {
            name: this.echarttsData.title,
            type: 'pie',
            radius: [15, 120],
            center: ['25%', '50%'],
            roseType: 'radius',
            itemStyle: {
              borderRadius: 5
            },
            label: {
             normal:{
             position:'inner',
             show:false,

             }
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: this.pieData,
        //     data:[
        //       { value: 40, name: 'rose 1' },
        // { value: 38, name: 'rose 2' },
        // { value: 32, name: 'rose 3' },
        // { value: 30, name: 'rose 4' },
        // { value: 28, name: 'rose 5' },
        // { value: 26, name: 'rose 6' },
        // { value: 22, name: 'rose 7' },
        // { value: 18, name: 'rose 8' }
        //     ]
          },

        ]
      };
      myChart.setOption(option, true); option && myChart.setOption(option,true);
    },


  }
};
</script>

<style scoped lang="scss">
.echartsHistogram {
  height: 370px;
  width: 660px;
  margin: auto;
  margin-bottom: 20px;
}
.time{
font-size: 14px;
font-family: Source Han Sans CN-Regular, Source Han Sans CN;
font-weight: 400;
color: #333333;
margin-right: 8px;
}
/deep/.el-dialog__title {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
</style>
