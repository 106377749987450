import { post, get } from "@/utils/http";

// 菜单树
export function getTagTree(params) {
    return get({ url: "/service-insight/macroeconomy/data/tagTree",params });
}
// 左侧地图
export function postList(params) {
    return post({ url: "/service-insight/macroeconomy/data/map/list",params });
}
// 右侧地图
export function postEcharts(params) {
    return post({ url: "/service-insight/macroeconomy/data/echarts",params });
}

